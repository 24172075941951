<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Returns') }}</div>
<!--            <AddNewButton :label="$t('Add')" @click="addNewItem" />-->
          </template>
          <template #end>
            <div class="p-d-flex p-ai-center p-mr-2" v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
              <!--            <InputSwitch id="form-agree-inputswitch" class="p-mr-2" v-model="privatePolicyIsAccepted"/>-->
              <Checkbox @change="getItems" id="returns-show-only-unfixed-checkbox" :binary="true" v-model="showOnlyUnfixed"/>
              <div>
                <label for="returns-show-only-unfixed-checkbox">
                  <div class="pointer p-p-2">Show only unfixed</div>
                </label>
              </div>
            </div>
            <CustomDropdown id="supplier"
                            class="p-mr-2"
                            :disabled="dataTableIsLoading"
                            style="min-width: 180px; max-width: 200px;"
                            ref="selectedSupplierDropdown"
                            :searchData="supplierSearchData"
                            v-model="selectedSupplier"
                            :options="suppliersData"
                            filterPlaceholder="Find supplier"
                            optionLabel="company_name"
                            :filter="true"
                            :filterFields="['name', 'company_name', 'reg_number']"
                            :loading="suppliersDataIsLoading"
                            @change="changeSupplier"
                            @filter="searchSuppliers"
                            :showClear="true">
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <div>{{ slotProps.value.company_name }} <span v-if="slotProps.value.name">({{ slotProps.value.name }})</span></div>
                </div>
                <span v-else>Select supplier</span>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option">
                  <div>{{ slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>
                </div>
              </template>
            </CustomDropdown>
<!--            <CustomDropdown class="p-mr-2"-->
<!--                            style="min-width: 180px; max-width: 200px;"-->
<!--                            :disabled="dataTableIsLoading"-->
<!--                            v-model="selectedType"-->
<!--                            :options="ReducedPositionTypes"-->
<!--                            filterPlaceholder="Select type"-->
<!--                            :preventFilter="true"-->
<!--                            @change="changeReducedPositionsType"-->
<!--                            :filter="false"-->
<!--                            :showClear="true">-->
<!--              <template #value="slotProps">-->
<!--                <div v-if="slotProps.value">-->
<!--                  {{ slotProps.value.label[$i18n.locale] }}-->
<!--                </div>-->
<!--                <span v-else>Select type</span>-->
<!--              </template>-->
<!--              <template #option="slotProps">-->
<!--                <div v-if="slotProps.option">-->
<!--                  {{ slotProps.option.label[$i18n.locale] }}-->
<!--                </div>-->
<!--              </template>-->
<!--            </CustomDropdown>-->
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
        <DesktopDataTable v-else
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Requests')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
                                {name: 'code', title: $t('Code'), sortable: false},
                                {name: 'name', title: $t('Name'), sortable: false},
                                {name: 'supplier', title: $t('Supplier'), sortable: false},
                                {name: 'qty', title: $t('Qty'), sortable: true},
                                {name: 'purchase_price', title: 'Buy', sortable: false},
                                {name: 'sell_price', title: 'Sell price', sortable: false},
                                {name: 'order', width: '40px', sortable: false},
                                // {name: 'type', title: $t('Type'), sortable: false},
                                {name: 'reason', title: $t('Reason'), sortable: false},
                                // {name: '', title: '', sortable: false},
                                {name: 'comment', sortable: false},
                                {name: 'fixed_qty', title: '', class: 'ti-link', sortable: false},
                                {name: 'created_at', title: $t('Created'), sortable: true},
                            ]">
<!--          <template v-slot:multiselectHeader>-->
<!--            <th v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">-->
<!--              <Checkbox :disabled="allCurrentItemsAreAdded" @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>-->
<!--            </th>-->
<!--          </template>-->
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}">
<!--              <td v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">-->
<!--                <Checkbox v-if="!isItemAdded(item.id)" :class="{ 'no-pointer':isItemAdded(item.id)}" :value="item.id" v-model="selectedItems"/>-->
<!--              </td>-->
              <td>
                <span v-if="item.position_data?.code">{{ item.position_data.code }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.position_data?.name">{{ item.position_data.name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.supplier">{{ item.supplier.company_name }}</span>
              </td>
              <td>{{ formatDecimal(item.qty) }}</td>
              <td>
                <span v-if="item.position_data?.purchase_price">{{ formatCurrency(item.position_data.purchase_price) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.position_data?.sell_price">{{ formatCurrency(item.position_data.sell_price) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.order && item.order.number">
                  <router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank" :class="{'highlight--link':isItemSelected(item.id)}"><i class="ti-hummer"></i></router-link>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
<!--              <td>-->
<!--                <span v-if="item.type">{{ ReducedPositionTypes.find(type => type.id === item.type)?.label[$i18n.locale] }}</span>-->
<!--                <i v-else class="ti-minus"></i>-->
<!--              </td>-->
              <td>
                <span v-if="item.reason">{{ ReducedPositionReasons.find(reason => reason.id === item.reason)?.label[$i18n.locale] }}</span>
                <router-link v-else-if="item.purchase_invoice_number" :to="{ path: '/purchase-invoices', query: { search: item.purchase_invoice_number } }" target="_blank">
                  <span>{{ item.purchase_invoice_number }}</span>
                </router-link>
                <i v-else class="ti-minus"></i>
              </td>
<!--              <td>-->
<!--                <PositionHistoryButton :position="item"/>-->
<!--              </td>-->
              <td>
                <span v-if="item.comment" @click.prevent href="" v-tooltip.top="item.comment"><i class="ti-comment-alt" ></i></span>
                <!--                <i v-else class="ti-minus"></i>-->
                <!--                <span v-if="item.comment">{{item.comment}}</span>-->
                <!--                <i v-else class="ti-minus"></i>-->
              </td>
              <td>
                <PositionFixedQtyButton v-if="item.fixed_qty && +item.fixed_qty" :position="item" :isReducedPosition="true"/>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) }},
                </span>
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
              <td>
                <div class="p-d-flex p-jc-end">
<!--                  <Button v-show="item.type === 2"-->
<!--                          v-tooltip.top="'Add item to warehouse'"-->
<!--                          style="margin: 0 3px;"-->
<!--                          class="p-button-secondary p-button-raised"-->
<!--                          @click="addReducedPositionToWarehouseBtnOnClick(item)">-->
<!--                    <i class="ti-plus p-mr-1"></i>-->
<!--                    <i class="ti-shopping-cart"></i>-->
<!--                  </Button>-->
                  <EditButton @click="editItem(item)" />
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <ReducedPositionModal :visible="itemModal"
                              :item="item"
                              :taxes="taxes"
                              @update-items="updateItems"
                              @close="closeItemModal"/>

<!--        <AddReducedPositionToWarehouseModal :visible="addReducedPositionToWarehouseModal"-->
<!--                                 :item="item"-->
<!--                                 @update-items="updateItems"-->
<!--                                 @close="closeAddReducedPositionToWarehouseModal"/>-->
			</div>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import settings from "@/settings";
import formatMixins from "@/mixins/formatMixins";
import PositionFixedQtyButton from "@/components/PositionFixedQtyButton";
import ReducedPositionTypes from "@/translations/ReducedPositionTypes";
import ReducedPositionReasons from "@/translations/ReducedPositionReasons";
import ReducedPositionModal from "@/pages/warehouse/components/ReducedPositionModal";
// import AddReducedPositionToWarehouseModal from "@/pages/warehouse/components/AddReducedPositionToWarehouseModal";

export default {
  mixins: [ httpMixins, datatableMixins, formatMixins ],
  components: { PositionFixedQtyButton, ReducedPositionModal },
	data() {
		return {
      showOnlyUnfixed: true,
      addReducedPositionToWarehouseModal: false,
      suppliers: null,
      fieldsExpand: 'creator,order,position_data,supplier,purchase_invoice_number',
      apiLink: 'reduced-position/get-reduced-positions',
      routePath: '/returns',
      sort: {
        column: 'id',
        direction: '-',
      },
      ReducedPositionTypes,
      ReducedPositionReasons,
		}
	},
  watch: {
    suppliers(value) {
      if (value?.length) {
        this.suppliersData = [...value]
      } else {
        this.suppliersData = null
      }
    },
    '$store.state.firstLayerIsOpened'(value) {
      if (!value) {
        if (this.addReducedPositionToWarehouseModal) {
          this.closeAddReducedPositionToWarehouseModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
    this.getSuppliers().then(() => this.checkSupplierInArray())
	},
  methods: {
    addReducedPositionToWarehouseBtnOnClick(item) {
      this.item = item
      this.addReducedPositionToWarehouseModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeAddReducedPositionToWarehouseModal() {
      this.addReducedPositionToWarehouseModal = false
      this.$store.commit('toggleFirstLayer', false)
      // this.item = {}
      // this.submitted = false
    },
    changeReducedPositionsType() {
      if (this.selectedTypeId === this.selectedType?.id) return false
      this.selectedTypeId = this.selectedType?.id
      // this.getItems()

      const route = {
        route: this.$route.path,
        query: {
          ...this.$route.query,
          type: this.selectedTypeId,
        }
      }

      if (!this.selectedTypeId && this.selectedTypeId !== 0) {
        delete route.query.type
      }
      this.$router.push(route)
    },
    // async updateRequestPosition(id) {
    //   this.showUpdatedItemPdfSpinner = true
    //   if (!id) return false
    //   this.updatedItemId = id
    //
    //   let foundItem = this.items?.find(item => item.id === id)
    //   if (foundItem) {
    //     const indexOfFoundItem = this.items.indexOf(foundItem)
    //     if (indexOfFoundItem !== -1) {
    //       const itemData = await this.getItemById(id)
    //       if (itemData) {
    //         this.items[indexOfFoundItem] = itemData
    //       }
    //     }
    //   }
    // },
    // async getItemById(id) {
    //   try {
    //     const { data, status } = await httpClient.post(`order-position/get-by-id?expand=${this.fieldsExpand}`, { id })
    //     if (status === 200 && data?.success && data.item) {
    //       return data.item
    //     } else {
    //       return null
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
    async getItems() {
      this.dataTableIsLoading = true
      // this.isLoading = true
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient(`${this.apiLink}`, {
          params: {
            supplierId: this.selectedSupplierId,
            page: this.pagination.currentPage,
            // typeId: this.selectedTypeId,
            typeId: 1,
            show_only_unfixed: +this.showOnlyUnfixed,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
	},
  computed: {
    itemsArrayIsEmpty() {
        return !this.items?.length
    },
    actionButtonsCount() {
      // if (this.$store.state.user.role === this.constants.userRoles.superAdmin ||
      //     this.$store.state.user.role === this.constants.userRoles.admin ||
      //     this.$store.state.user.role === this.constants.userRoles.accountant) {
      //   return 1
      // } else {
      //   return 0
      // }
      // if (this.items?.find(i => i.type === 2)) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 1
    },
  }
}
</script>